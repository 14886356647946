<template>
    <!-- <Layout> -->
    <div class="px-1 mt-1">
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0 font-size-18">
                {{ $t('event form') }}
            </h4>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card form">
                    <div class="card-body">
                        <div v-if="loading">
                            <LoadingTableForm
                                :cols="2"
                                :rows="6"
                                class="table-bordered bg-white" />
                        </div>
                        <b-form v-else @submit.prevent="submitForm">
                            <table
                                class="table table-bordered table-wrap table-form">
                                <thead>
                                    <tr class="bg-dark bg-gradient">
                                        <th
                                            class="text-light text-start"
                                            colspan="6">
                                            {{ $t('compose') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th class="bg-light bg-soft">
                                            {{ $t('subject') }}
                                        </th>
                                        <td>
                                            <input
                                                type="text"
                                                :placeholder="`${$t(
                                                    'enter subject'
                                                )}`"
                                                v-model="pl.subject"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.subject.$error,
                                                }" />
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .subject.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="3">
                                            <div ref="quillWrapper">
                                                <QuillEditor
                                                    contentType="html"
                                                    :placeholder="
                                                        $t('enter content here')
                                                    "
                                                    v-model:content="pl.content"
                                                    :class="`ql-container ql-snow ${
                                                        submitted &&
                                                        v$.pl.content.$error
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`" />
                                            </div>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .content.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center">
                                <button type="submit" class="btn btn-danger">
                                    {{ $t('save') }}
                                </button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </Layout> -->
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTableForm from '@/components/loading-table-form';

import Switches from 'vue-switches';
import { mapActions, mapGetters } from 'vuex';
import ui from '../../../mixins/ui';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import string from '../../../mixins/string';
import convert from '../../../mixins/convert';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Switches,
        Multiselect,
        LoadingTableForm,
    },
    mixins: [datetime, number, string, convert, ui],
    data() {
        return {
            title: null,
            submitted: false,
            loading: false,
            _id: this.$route.query.id,
            pl: {
                subject: null,
                content: null,
            },
            quillHeight: 0,
        };
    },
    validations() {
        return {
            pl: {
                subject: {
                    required: helpers.withMessage('required', required),
                },
                content: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    computed: {
        viewMode() {
            return this._id != null;
        },
        forms() {
            var m_fd = this.formDataToObject(this.objectToFormData(this.pl));
            var o_fd = this.formDataToObject(
                this.objectToFormData(this.pl_origin)
            );
            var f_fd = new FormData();
            var rows = '';
            for (var key in m_fd) {
                if (m_fd.hasOwnProperty(key)) {
                    var m_value =
                        m_fd[key] == 'true'
                            ? '1'
                            : m_fd[key] == 'false'
                            ? '0'
                            : m_fd[key];
                    var o_value =
                        o_fd[key] == 'true'
                            ? '1'
                            : o_fd[key] == 'false'
                            ? '0'
                            : o_fd[key];
                    var bool_values = [
                        ['inactive', 'active'],
                        ['disabled', 'enabled'],
                    ];
                    var bool_value =
                        key == 'status' ? bool_values[0] : bool_values[1];
                    if (!this.viewMode) {
                        // CREATE MODE
                        if (m_fd[key] != null) {
                            f_fd.append(key, m_value);
                        }
                    } else {
                        // EDIT MODE
                        if (
                            m_fd[key] != o_fd[key] &&
                            !['agent[commissions][enabled]'].includes(key)
                        ) {
                            f_fd.append(key, m_value);

                            rows += `<tr>
                                    <td>${this.arrayToArrows(key)}</td>
                                    <td class="${
                                        o_fd[key] == 'true'
                                            ? 'text-primary'
                                            : o_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            o_fd[key] == 'true'
                                                ? bool_value[1]
                                                : o_fd[key] == 'false'
                                                ? bool_value[0]
                                                : o_fd[key]
                                        }
                                    </td>
                                    <td class="${
                                        m_fd[key] == 'true'
                                            ? 'text-primary'
                                            : m_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            m_fd[key] == 'true'
                                                ? bool_value[1]
                                                : m_fd[key] == 'false'
                                                ? bool_value[0]
                                                : m_fd[key]
                                        }
                                    </td>
                                </tr>`;
                        }
                    }
                }
            }

            return {
                obj_array: this.formDataToObject(f_fd),
                form_data: f_fd,
                x_www_form_urlencoded: this.formDataToUrlEncoded(f_fd),
                changes: rows,
                original: o_fd,
                modified: m_fd,
            };
        },
    },
    watch: {
        'pl.content'() {
            this.quillHeight = this.$refs.quillWrapper.clientHeight - 216;
        },
        quillHeight(newVal, oldVal) {
            var addedHeight = newVal - oldVal;
            window.resizeTo(
                window.outerWidth,
                window.outerHeight + addedHeight
            );
        },
    },
    methods: {
        ...mapActions('event', {
            eventCreate: 'create',
            eventUpdate: 'update',
            eventGetList: 'getList',
            eventView: 'view',
        }),
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            } else {
                if (this.viewMode && !this.forms.changes) {
                    Swal.fire({
                        icon: 'info',
                        title: this.$t('no changes made'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return;
                }

                var fd = new FormData();

                fd.append('subject', this.pl.subject);
                fd.append('content', this.pl.content);
                console.log(fd.get('content'));
                const res = !this.viewMode
                    ? await this.eventCreate({
                          form_data: fd,
                      })
                    : await this.eventUpdate({
                          form_data: this.formDataToUrlEncoded(fd),
                          id: this._id,
                      });

                var title = {
                    create: res.success
                        ? this.$t('event has been created')
                        : this.$t('creating failed'),
                    update: res.success
                        ? this.$t('event has been updated')
                        : this.$t('updating failed'),
                };

                if (res) {
                    if (res.success) {
                        window.opener.postMessage('reload-events');
                        if (!this.viewMode) {
                            this.reset();
                        } else {
                            this.getDetails();
                        }
                    }
                    Swal.fire({
                        title: this.viewMode ? title.update : title.create,
                        icon: res.success ? 'success' : 'error',
                        timer: 2000,
                    });
                }
            }
        },
        async getDetails() {
            if (!this._id) {
                // this.pl_origin = JSON.parse(JSON.stringify(this.pl))
                return;
            }
            this.submitted = false;
            const data = await this.eventView(this._id);

            this.pl.subject = data.subject;
            this.pl.content = await data.content;

            this.pl_origin = JSON.parse(JSON.stringify(data));
        },
        reset() {
            this.submitted = false;
            this.pl.subject = null;
            this.pl.content = null;
            document.querySelector('.ql-editor').innerHTML = null;
        },
    },
    mounted() {
        this.getDetails();
        window.resizeTo(window.outerWidth, 600);
    },
};
</script>
