export default {
    methods: {
        // arrayToArrows(value) {
        //     const matches = value.match(/\[([^\]]+)\]/g);

        //     if (matches) {
        //         return matches.map(str => str.slice(1, -1)).join('->');
        //     } else {
        //         return value;
        //     }
        // }
        arrayToArrows(value) {
            return value.replace(/\[(.*?)\]/g, '->$1');
        }
    }
}